import React, { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { MenuGroup, Section } from '@atlaskit/menu';

import type { PageMode } from '@confluence/copy-content-link';
import { useShareToEntities } from '@confluence/share/entry-points/useShareToEntities';
import { PAGE_MODE } from '@confluence/share/entry-points/useCopyContentLink';

import type { SuggestedUser } from './NoCollaboratorsDialog';
import { NoCollaboratorsDialogShareSuggestionMenuItem } from './NoCollaboratorsDialogShareSuggestionMenuItem';

const i18n = defineMessages({
	suggestions: {
		id: 'team-presence.menu.empty-state.suggestions.title',
		defaultMessage: 'Suggestions based on people you work with',
		description:
			'Text shown as a title of a section inside of a menu. The people in the section are suggestions based on people you work with',
	},
});

export type NoCollaboratorsDialogShareSuggestionMenuProps = {
	contentId: string;
	contentMode: 'live' | 'edit' | 'view';
	contentType: string;
	pageMode: PageMode;
	shareButtonLabelBeforeClick: string;
	shareButtonLabelAfterClick: string;
	suggestions: SuggestedUser[];
};
export const NoCollaboratorsDialogShareSuggestionMenu = ({
	contentId,
	contentMode,
	contentType,
	pageMode,
	shareButtonLabelBeforeClick,
	shareButtonLabelAfterClick,
	suggestions,
}: NoCollaboratorsDialogShareSuggestionMenuProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();

	const isEditMode = pageMode === 'edit';

	const { shareToEntities } = useShareToEntities(
		contentId,
		'noCollaboratorsDialog',
		isEditMode ? PAGE_MODE.EDIT : PAGE_MODE.VIEW,
	);

	useEffect(() => {
		createAnalyticsEvent({
			type: 'sendScreenEvent',
			data: {
				name: 'noCollaboratorsDialogShareSuggestions',
				attributes: {
					contentMode,
					contentType,
					suggestionsCount: suggestions.length,
				},
			},
		}).fire();
	}, [createAnalyticsEvent, contentMode, contentType, suggestions]);

	return (
		<MenuGroup>
			<Section title={formatMessage(i18n.suggestions)}>
				{suggestions.map((suggestion) => {
					const handleShareClick = () => {
						shareToEntities([{ id: suggestion.id, type: 'user' }], isEditMode);
					};

					return (
						<NoCollaboratorsDialogShareSuggestionMenuItem
							key={suggestion.id}
							suggestion={suggestion}
							shareButtonLabelBeforeClick={shareButtonLabelBeforeClick}
							shareButtonLabelAfterClick={shareButtonLabelAfterClick}
							onHandleShare={handleShareClick}
						/>
					);
				})}
			</Section>
		</MenuGroup>
	);
};
