import { useContext, useEffect } from 'react';

import { useAccessStatus } from '@confluence/session-data';
import { SPAViewContext } from '@confluence/spa-view-context';
import {
	deserializeState,
	useGetOnboardingState,
	useSetOnboardingState,
} from '@confluence/onboarding-helpers/entry-points/hooks/useOnboardingState';
import { ONBOARDING_PRESENCE_PAGE_STATE_KEYS } from '@confluence/onboarding-helpers/entry-points/constants/onboarding-state-constants';

export const useIsSpotlightEligible = () => {
	const { isNewUser } = useContext(SPAViewContext);
	const { isLicensed, isGuest } = useAccessStatus();
	const { setOnboardingState } = useSetOnboardingState();

	const isEligible = !isNewUser && (isLicensed || isGuest);

	const {
		data: onboardingStateData,
		loading: onboardingStateLoading,
		error: onboardingStateError,
	} = useGetOnboardingState(
		ONBOARDING_PRESENCE_PAGE_STATE_KEYS.PRESENCE_AVATAR_GROUP_PAGE_SPOTLIGHT_SEEN,
	);

	const { pagePresenceAvatarGroupSpotlightSeen } = deserializeState(onboardingStateData);

	useEffect(() => {
		if (!isEligible) {
			void setOnboardingState({
				key: ONBOARDING_PRESENCE_PAGE_STATE_KEYS.PRESENCE_AVATAR_GROUP_PAGE_SPOTLIGHT_SEEN,
				value: 'true',
			});
		}
	}, [isEligible, setOnboardingState]);

	return {
		isSpotlightEligible: !pagePresenceAvatarGroupSpotlightSeen && isEligible,
		onboardingStateLoading,
		onboardingStateError,
	};
};
