import { getApolloClient, getAGGClient } from '@confluence/graphql';

import type { PresenceUser } from '../presenceTypes';

import {
	type PresenceProviderUserQuery as PresenceProviderUserQueryType,
	type PresenceProviderUserQueryVariables,
} from './__types__/PresenceProviderUserQuery';
import type { Users, UsersVariables } from './__types__/PresenceProviderUsersAggQuery';
import { PresenceProviderUserQuery } from './PresenceProviderUserQuery.graphql';
import { PresenceProviderAGGUserQuery } from './PresenceProviderUsersQuery.agggraphql';

type Props = {
	isGuestOrAnonymous: boolean;
	onComplete(result: PresenceUser[]): void;
	onError(error: any): void;
};

export const usePresenceProviderQuery = ({ isGuestOrAnonymous, onComplete, onError }: Props) => {
	// need to use the non hook variety. useLazyHook is a good candidate; however trying to execute the query multiple
	// times won't cause a re-render if there's a cache hit. So use this way instead
	const getConfluenceUsers = (accountIds: string[]) => {
		return getApolloClient()
			.query<PresenceProviderUserQueryType, PresenceProviderUserQueryVariables>({
				query: PresenceProviderUserQuery,
				variables: { accountIds },
			})
			.then(({ data }) => {
				const result: PresenceUser[] = [];
				data?.confluenceUsers?.nodes
					?.filter((user) => user)
					.forEach((user) => {
						const accountId = (user as any)?.accountId;
						// even though we filter out null users, the ts check thinks users can be null
						if (accountId) {
							result.push({
								accountId,
								name: user?.displayName ?? '',
								permissionType: user?.permissionType ?? undefined,
								avatarPath: user?.profilePicture?.path ?? '',
							});
						}
					});

				onComplete(result);
			})
			.catch(onError);
	};

	const getUsersFromAGG = (accountIds: string[]) => {
		return getAGGClient()
			.query<Users, UsersVariables>({
				query: PresenceProviderAGGUserQuery,
				variables: { accountIds },
			})
			.then(({ data }) => {
				const result: PresenceUser[] = [];

				data?.users
					?.filter((user) => user)
					.forEach((user) => {
						const accountId = user.accountId;
						if (accountId) {
							result.push({
								accountId,
								name: user.name ?? '',
								avatarPath: user.picture ?? '',
							});
						}
					});

				onComplete(result);
			})
			.catch(onError);
	};

	const getUsers = (accountIds: string[]) => {
		return isGuestOrAnonymous ? getUsersFromAGG(accountIds) : getConfluenceUsers(accountIds);
	};

	return {
		getUsers,
	};
};
