import { useEffect, useState, useCallback, useRef } from 'react';
import { v4 as uuid } from 'uuid';

import type {
	CollabEventPresenceData,
	CollabPresenceActivityChangePayload,
	Provider,
} from '@atlaskit/collab-provider';
import { createSocketIOCollabProvider } from '@atlaskit/collab-provider/socket-io-provider';
import type { PresenceActivity } from '@atlaskit/editor-common/collab';

import {
	type CollabEditState,
	fetchCollabPermissionToken,
	useNativeCollabPresenceState,
	useNativeCollabState,
} from '@confluence/native-collab';
import { useSessionData, useIsGuest } from '@confluence/session-data';
import { markErrorAsHandled } from '@confluence/graphql';

import { usePresenceStoreActions, usePresenceStoreState } from '../presence-store';
import type { ParticipantsToHydrate } from '../presence-store/usePresenceStore';
import {
	getCurrentUserFromMap,
	MAX_GROUP_SIZE,
	CURRENT_USER_DUMMY_SESSION_ID,
} from '../presenceUtils';
import type { ContentMode, PresenceUser } from '../presenceTypes';

import { usePresenceProviderQuery } from './usePresenceProviderQuery';
import { usePresenceCurrentUser } from './usePresenceCurrentUser';
import { usePresenceLimiter } from './usePresenceLimiter';
// 0.5 sec
const INTERVAL_TIME = 500;
const MAX_FETCH_SIZE = 25;
/**
 * Gets the URL of the presence service.
 *
 * @returns {string} The URL of the presence service.
 */
export const getPresenceServiceUrl = (): string => {
	const route = 'collab-presence-confluence';

	if (window.location.hostname === 'localhost') {
		const host = (window as any).__backendOriginHost;

		return `https://${host}/${route}`;
	}

	return `${window.location.origin}/${route}`;
};

/**
 * Generates a unique presence client ID.
 *
 * @returns {string} A string representing the unique presence client ID, prefixed with 'presence-'.
 */
export const getPresenceClientId = (): string => {
	return `presence-${uuid()}`;
};

export const usePresenceProvider = (
	contentId: string,
	contentType: string,
	contentMode: ContentMode,
	activity: PresenceActivity,
) => {
	const shouldCreatePresenceConnection = contentMode === 'view';

	const [{ collabEditInitialized, collabEditProvider }] = useNativeCollabState();
	const [isLoading, setIsLoading] = useState(true);

	const participantsToHydrateRef = useRef<Record<string, ParticipantsToHydrate>>({});

	const sessions = useRef<Set<string>>(new Set());
	const providerRef = useRef<Provider | (CollabEditState & Provider) | null>(collabEditProvider);
	const [networkError, setNetworkError] = useState(undefined);

	// presenceId is pulled from the native collab state to ensure that we have a consistent presenceId for a given contentId
	// across the collab providers that drive editor as well as presence components (telepointer and avatar colors).
	const [{ presenceId }] = useNativeCollabPresenceState();

	const presenceIdRef = useRef<string>(presenceId);
	const { participants } = usePresenceStoreState();
	const { cloudId, userId, isLoggedIn } = useSessionData();
	const isGuest = useIsGuest();

	const isGuestOrAnonymous = isGuest || !isLoggedIn;

	const {
		addParticipant,
		addHydratedParticipant,
		addParticipantFromCollabSocket,
		removeParticipant,
		resetParticipants,
		updateSessionData,
	} = usePresenceStoreActions();

	usePresenceLimiter({
		contentId,
		contentType,
		contentMode,
		participants,
		provider: providerRef.current,
	});

	const handleGetCurrentUser = (currentUser: PresenceUser) => {
		addHydratedParticipant(
			{
				...currentUser,
				sessions: {
					[CURRENT_USER_DUMMY_SESSION_ID]: {
						// always default to viewer for live pages and view pages.
						// See https://product-fabric.atlassian.net/browse/MDS-1192 for more explanation
						activity: contentMode === 'edit' ? 'editor' : 'viewer',
					},
				},
				presenceId: currentUser.accountId,
			},
			participantsToHydrateRef,
		);
	};

	useEffect(() => {
		// update only the current users presence activity
		// need to handle small blips when switching between view and edit where current user activity doesn't update fast enough
		// otherwise, there will be a short period of time where the current user shows the incorrect status
		if (userId) {
			updateSessionData({
				id: userId,
				sessionId: CURRENT_USER_DUMMY_SESSION_ID,
				presenceActivity: shouldCreatePresenceConnection ? 'viewer' : 'editor',
			});
		}
	}, [shouldCreatePresenceConnection, updateSessionData, userId]);

	const onHandleFetchUsersComplete = (users: PresenceUser[]) => {
		users.forEach((user) => {
			const { accountId } = user;
			const participant = participantsToHydrateRef.current[accountId];

			if (participant) {
				addHydratedParticipant(
					{
						...user,
						sessions: participant.sessions,
						presenceId: participant.presenceId!,
					},
					participantsToHydrateRef,
				);
			}
		});
	};

	const handleFetchUsersError = (error: any) => {
		// want to prevent an infinite spam of api calls being made
		setNetworkError(error);
		markErrorAsHandled(error);
	};

	const { getUsers: getUserInformation } = usePresenceProviderQuery({
		isGuestOrAnonymous,
		onComplete: onHandleFetchUsersComplete,
		onError: handleFetchUsersError,
	});

	usePresenceCurrentUser({
		isGuest,
		isAnonymous: !isLoggedIn,
		onComplete: handleGetCurrentUser,
		onError: handleFetchUsersError,
	});

	const handleOnViewPresenceUpdate = useCallback(
		({ joined, left }: CollabEventPresenceData) => {
			joined?.map((joinedParticipant) => {
				addParticipant({ ...joinedParticipant }, participantsToHydrateRef);
			});

			left?.map((participant: { sessionId: string }) => {
				removeParticipant(participant.sessionId, participantsToHydrateRef);
			});
		},
		// don't let this depend on anything with frequent state changes to avoid unnecessary subscribitions and unsubscriptions of the event handling
		[addParticipant, removeParticipant],
	);

	const handleOnEditorPresenceUpdate = useCallback(
		() => {
			const people = collabEditProvider?.getParticipants();

			people?.forEach((participant) => {
				sessions.current.add(participant.sessionId);
				addParticipantFromCollabSocket(participant);
			});

			const participantSessions = people?.map((p) => p.sessionId) ?? [];

			// getParticipants returns all active users, so need to determine which ones to remove
			sessions.current.forEach((sessionId) => {
				if (!participantSessions.includes(sessionId)) {
					removeParticipant(sessionId, participantsToHydrateRef);
					sessions.current.delete(sessionId);
				}
			});
		},
		// don't let this depend on anything with frequent state changes to avoid unnecessary subscribitions and unsubscriptions of the event handling
		[collabEditProvider, addParticipantFromCollabSocket, removeParticipant],
	);

	const handleOnPresence = shouldCreatePresenceConnection
		? handleOnViewPresenceUpdate
		: handleOnEditorPresenceUpdate;

	// TODO: Hmm, this is a noop. We should probably remove this.
	const handleOnViewPresenceChange = useCallback(() => {}, []);

	const handleOnEditorPresenceChange = useCallback(
		(_: CollabPresenceActivityChangePayload) => {
			handleOnEditorPresenceUpdate();
		},
		[handleOnEditorPresenceUpdate],
	);

	const handleOnPresenceChange = shouldCreatePresenceConnection
		? handleOnViewPresenceChange
		: handleOnEditorPresenceChange;

	const fetchMore = (count: number) => {
		const accountIds = Object.keys(participantsToHydrateRef.current).slice(0, count);
		if (accountIds.length > 0) {
			void getUserInformation(accountIds);
		}
	};

	const initializeProvider = useCallback(async () => {
		if (!shouldCreatePresenceConnection) {
			setIsLoading(false);
			return;
		}

		const permissionTokenRefresh = fetchCollabPermissionToken(contentId, undefined);

		const documentAri = `ari:cloud:confluence:${cloudId}:${contentType}/${contentId}`;

		// In order to support deduplication of the collaborator, presenceId will be set to the userId if it exists.
		// This means that anonymous users will still rely on presenceId and not get deduplicated.
		const collaboratorPresenceId = userId ?? presenceIdRef.current;

		try {
			const provider = createSocketIOCollabProvider({
				url: getPresenceServiceUrl(),
				documentAri,
				permissionTokenRefresh,
				need404: true,
				isPresenceOnly: true,
				productInfo: {
					product: 'confluence',
					subProduct: 'team-presence',
				},
				presenceId: collaboratorPresenceId,
			});

			// Since we moved to only creating a presence connection on view pages, we should always be a viewer
			provider.setupForPresenceOnly(getPresenceClientId(), 'viewer');

			providerRef.current = provider;
		} finally {
			setIsLoading(false);
		}
	}, [shouldCreatePresenceConnection, cloudId, contentId, contentType, userId]);

	useEffect(() => {
		// When setting up the provider, whether or not it's a presence provider or editor provider. We should clear our
		// participant state, since this is based on contentId, there are edge cases that would cause participants to be sticky
		// as we switch between view and edit.
		participantsToHydrateRef.current = {};
		resetParticipants(userId);

		void initializeProvider();

		// When we don't have a provider and we are not creating a presence connection,
		// we should use the existing collabEditProvider, there was an edge case where this provider wasn't
		// available on the first render.
		if (!providerRef.current && collabEditProvider) {
			providerRef.current = collabEditProvider;
		}

		providerRef.current?.on('presence', handleOnPresence);
		providerRef.current?.on('presence:changed', handleOnPresenceChange);

		return () => {
			setIsLoading(true);

			providerRef.current?.off('presence', handleOnPresence);
			providerRef.current?.off('presence:changed', handleOnPresenceChange);

			// we do not want to destroy the live connection, since we didn't create it
			if (shouldCreatePresenceConnection) {
				providerRef.current?.destroy();
			}
		};
	}, [
		collabEditProvider,
		shouldCreatePresenceConnection,
		initializeProvider,
		handleOnPresence,
		handleOnPresenceChange,
		resetParticipants,
		userId,
	]);

	useEffect(() => {
		presenceIdRef.current = presenceId;
	}, [presenceId]);

	useEffect(() => {
		if (shouldCreatePresenceConnection || !collabEditInitialized) {
			return;
		}

		providerRef.current?.sendMessage({
			type: 'participant:activity',
			activity,
		});
	}, [activity, collabEditInitialized, shouldCreatePresenceConnection]);

	useEffect(() => {
		if (!shouldCreatePresenceConnection) {
			return;
		}

		// While the current user's key has not been hydrated, or the group size is less than the max group size before we
		// render the overflow. We will continue to hydrate the next keys every interval.
		const interval = setInterval(() => {
			const hasCurrentUserKeyBeenHydrated = getCurrentUserFromMap(
				participants,
				providerRef.current?.getSessionId(),
			);

			const nextKeysToHydrate = Object.keys(participantsToHydrateRef.current).slice(
				0,
				MAX_GROUP_SIZE + 1,
			);

			const shouldHydrateNextKeys =
				nextKeysToHydrate.length > 0 &&
				(!hasCurrentUserKeyBeenHydrated || participants.size <= MAX_GROUP_SIZE);

			if (shouldHydrateNextKeys) {
				// Keys in this scenario are actually presenceIds, but they currently map to accountIds
				// unless the user is anonymous. We short circuit anonymous users out of hydration, so they
				// will not be in this list.
				void getUserInformation(nextKeysToHydrate);
			}
		}, INTERVAL_TIME);

		if (networkError && interval) {
			clearInterval(interval);
		}

		return () => clearInterval(interval);
	}, [networkError, shouldCreatePresenceConnection, participants, getUserInformation]);

	return {
		// only returning this for testing purposes until I figure out something better
		participantsToHydrateRef,
		isLoading,
		presenceId,
		// want to return DUMMY as a default to preserve activity lookup if provider isn't available yet
		sessionId: providerRef.current?.getSessionId() ?? CURRENT_USER_DUMMY_SESSION_ID,
		fetchMore: () => fetchMore(MAX_FETCH_SIZE),
		networkError,
	};
};
