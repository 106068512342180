import { useCallback, useEffect } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import type { Provider } from '@atlaskit/collab-provider';

import { useSessionData } from '@confluence/session-data';
import { fg } from '@confluence/feature-gating';

import type { Participant } from '../presence-store';
import type { ContentMode } from '../presenceTypes';

//TODO dynamic configs https://product-fabric.atlassian.net/browse/MDS-1207
const SESSION_LIMIT = 50;
const TOTAL_SESSION_LIMIT = 1000;

type Props = {
	contentId: string;
	contentMode: ContentMode;
	contentType: string;
	participants: Map<string, Participant>;
	provider: Provider | null;
};

export const usePresenceLimiter = ({
	contentId,
	contentMode,
	contentType,
	participants,
	provider,
}: Props) => {
	const { userId } = useSessionData();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const getTotalSessions = useCallback(() => {
		return provider?.getParticipants().length ?? 0;
	}, [provider]);

	const checkSessionLimit = useCallback(
		(currentUser: Participant | undefined, totalSessions: number) => {
			const currentUserHasReachedSessionLimit =
				currentUser && Object.keys(currentUser.sessions).length > SESSION_LIMIT;

			if (currentUserHasReachedSessionLimit || totalSessions > TOTAL_SESSION_LIMIT) {
				provider?.destroy();

				createAnalyticsEvent({
					type: 'sendTrackEvent',
					data: {
						source: 'presenceAvatarGroup',
						action: 'destroyConnection',
						actionSubject: 'presenceAvatarGroupConnection',
						attributes: {
							sessionLimitType: currentUserHasReachedSessionLimit ? 'currentUser' : 'totalSessions',
							contentId,
							contentType,
						},
					},
				}).fire();
			}
		},
		[contentId, contentType, createAnalyticsEvent, provider],
	);

	useEffect(() => {
		// only for presence connections
		if (!fg('confluence_team_presence_limiter') || contentMode !== 'view') {
			return;
		}

		const currentUser = participants.get(userId ?? 'unidentified');
		checkSessionLimit(currentUser, getTotalSessions());
	}, [checkSessionLimit, contentMode, participants, userId, getTotalSessions]);

	return {};
};
