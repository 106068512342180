import React, { useCallback, useState, useEffect, useContext, forwardRef } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import { v4 as uuid } from 'uuid';

import AvatarGroup from '@atlaskit/avatar-group';
import { token } from '@atlaskit/tokens';
import { Box, Pressable, xcss } from '@atlaskit/primitives';
import Popup from '@atlaskit/popup';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { usePageSpaceKey } from '@confluence/page-context';
import { ShareAndRestrictDialogController } from '@confluence/share-and-restrict-dialog/entry-points/ShareAndRestrictDialogController';
import { fg } from '@confluence/feature-gating';
import {
	ExperienceTrackerContext,
	TEAM_PRESENCE_AVATAR_GROUP_MENU_VIEW_EXPERIENCE,
	TEAM_PRESENCE_AVATAR_GROUP_VIEW_EXPERIENCE,
} from '@confluence/experience-tracker';
import { useSessionData, useIsGuest } from '@confluence/session-data';
import { useUnifiedShareDialogEligible } from '@confluence/experiment-unified-share-dialog';

import { getParticipantColors, getCurrentUserColors, MAX_GROUP_SIZE } from '../presenceUtils';
import type { Participant } from '../presence-store';
import type { ContentMode } from '../presenceTypes';

import { PresenceMenu } from './PresenceMenu';
import { PresenceAvatar } from './PresenceAvatar';
import { NoCollaboratorsDialog } from './NoCollaboratorsDialog';
import { switchToShareDialog } from './switchToShareDialog';

const i18n = defineMessages({
	overflowLabel: {
		id: 'team-presence.avatar-group.overflow-label',
		defaultMessage: 'Overflow menu',
		description: 'Accessible label for presence avatar group overflow menu',
	},
	blogpostLandmark: {
		id: 'team-presence.avatar-group.blogpost-landmark',
		defaultMessage: 'Active people on blog post',
		description: 'Accessible region landmark for presence avatar group when on a blog post',
	},
	pageLandmark: {
		id: 'team-presence.avatar-group.page-landmark',
		defaultMessage: 'Active people on page',
		description: 'Accessible region landmark for presence avatar group when on a page',
	},
});

export type PresenceAvatarGroupProps = {
	contentId: string;
	contentMode: ContentMode;
	contentType: string;
	participants: Participant[];
	analyticSource?: string;
	popupOpen?: boolean;
	onLoadMoreClick(): void;
	totalParticipants: number;
};
const popupContainerStyles = xcss({
	backgroundColor: 'elevation.surface.overlay',
	boxShadow: 'elevation.shadow.overlay',
	borderRadius: 'border.radius',
	position: 'relative',
	width: '360px',
});

const editObjectHeaderStyles = xcss({
	paddingTop: 'space.0',
	paddingBottom: 'space.0',
});

const overflowMoreStyles = xcss({
	padding: 'space.075',
	backgroundColor: 'color.background.accent.gray.subtler',
	borderRadius: 'border.radius.400',
	color: 'color.text.subtle',
	font: 'font.heading.xxsmall',
	':hover': {
		backgroundColor: 'color.background.accent.gray.subtler.hovered',
	},
	':active': {
		backgroundColor: 'color.background.accent.gray.subtler.pressed',
	},
});

const moreActiveStyles = xcss({
	backgroundColor: 'color.background.selected',
	color: 'color.text.selected',
	':hover': {
		backgroundColor: 'color.background.selected.hovered',
	},
	':active': {
		backgroundColor: 'color.background.selected.pressed',
	},
});

const indicatorStyles = xcss({
	minWidth: '28px',
	backgroundColor: 'color.background.neutral.subtle',
	padding: 'space.0',
	margin: 'space.025',
	borderRadius: 'border.radius.100',
	':focus-visible': {
		outlineColor: 'color.border.focused',
		outlineStyle: 'solid',
		outlineWidth: 'border.width.outline',
		outlineOffset: 'space.050',
	},
});

const indicatorOverflowStyles = xcss({
	minWidth: '35px',
	maxWidth: '35px',
});

export const PresenceAvatarGroup = forwardRef<HTMLDivElement, PresenceAvatarGroupProps>(
	(
		{
			contentId,
			contentMode,
			contentType,
			analyticSource,
			popupOpen,
			onLoadMoreClick,
			participants,
			totalParticipants,
		}: PresenceAvatarGroupProps,
		ref,
	) => {
		const { formatMessage } = useIntl();
		const { createAnalyticsEvent } = useAnalyticsEvents();
		const experienceTracker = useContext(ExperienceTrackerContext);

		const { isLoggedIn } = useSessionData();
		const isGuest = useIsGuest();

		const [spaceKey] = usePageSpaceKey();

		const [isPopupOpen, setIsPopupOpen] = useState(popupOpen || false);
		const [isShareDialogOpen, setIsShareDialogOpen] = useState(false);
		const { isUSDExperimentEnabled } = useUnifiedShareDialogEligible();

		// This will be passed to the NoCollaboratorsDialog to ensure that we don't
		// execute the suggested user query multiple times when the dialog is opened and closed
		const [suggestedUserQuerySessionId] = useState(uuid());

		useEffect(() => {
			experienceTracker.succeed({
				name: TEAM_PRESENCE_AVATAR_GROUP_VIEW_EXPERIENCE,
			});
		}, [experienceTracker]);

		const isAllowedToShare = useCallback(() => {
			// only 1 user, not anonymous and not guest user
			return participants.length === 1 && isLoggedIn && !isGuest;
		}, [participants, isLoggedIn, isGuest]);

		useEffect(() => {
			if (isPopupOpen && participants.length > 1 && participants.length <= MAX_GROUP_SIZE) {
				setIsPopupOpen(false);
			}
		}, [isPopupOpen, participants]);

		const handleShareClick = useCallback(
			(analyticsTriggerSource: string) => {
				setIsPopupOpen(false);
				let shareDialogType;
				if (fg('confluence_frontend_unified_restrict_and_share') || isUSDExperimentEnabled) {
					shareDialogType = 'v2';
					setIsShareDialogOpen(true);
				} else {
					shareDialogType = 'old';
					switchToShareDialog((openShare) => {
						if (openShare) {
							openShare();
						}
					});
				}
				createAnalyticsEvent({
					type: 'sendUIEvent',
					data: {
						source: analyticsTriggerSource,
						action: 'clicked',
						actionSubject: 'button',
						actionSubjectId: 'presenceShare',
						attributes: {
							contentId,
							contentMode,
							contentType,
							shareDialog: shareDialogType,
						},
					},
				}).fire();
			},
			[contentId, contentMode, contentType, createAnalyticsEvent, isUSDExperimentEnabled],
		);

		const toggleEmptyPresenceDialog = useCallback(() => {
			if (isAllowedToShare()) {
				setIsPopupOpen((isPopupOpenState) => !isPopupOpenState);

				createAnalyticsEvent({
					type: 'sendUIEvent',
					data: {
						source: analyticSource,
						action: 'clicked',
						actionSubject: 'button',
						actionSubjectId: 'singlePresenceAvatarGroup',
						product: 'confluence',
						attributes: {
							contentId,
							contentMode,
							contentType,
							toggle: isPopupOpen ? 'close' : 'open',
						},
					},
				}).fire();
			}
		}, [
			contentId,
			contentMode,
			contentType,
			analyticSource,
			createAnalyticsEvent,
			isPopupOpen,
			isAllowedToShare,
		]);

		const isSingleParticipant = Boolean(participants && totalParticipants === 1);

		const onMoreClick = useCallback(
			(e: React.MouseEvent<Element, MouseEvent>, number: number) => {
				createAnalyticsEvent({
					type: 'sendUIEvent',
					data: {
						source: analyticSource,
						action: 'clicked',
						actionSubject: 'button',
						actionSubjectId: 'presenceAvatarGroupMoreButton',
						product: 'confluence',
						attributes: {
							contentId,
							contentType,
							contentMode,
							number,
						},
					},
				}).fire();

				experienceTracker.start({
					name: TEAM_PRESENCE_AVATAR_GROUP_MENU_VIEW_EXPERIENCE,
				});

				setIsPopupOpen((isPopupOpenState) => !isPopupOpenState);

				// platform-design-system-popup-ref causes the popup to close when the user clicks on the more button,
				// revisit what other types of changes we can put in place to not have to call stopPropagation
				e.stopPropagation();
			},
			[
				contentId,
				contentType,
				contentMode,
				analyticSource,
				createAnalyticsEvent,
				experienceTracker,
			],
		);

		const triggerId = isSingleParticipant ? 'no-collaborators-dialog' : 'presence-menu';

		const PopupContent = isSingleParticipant ? (
			<NoCollaboratorsDialog
				contentId={contentId}
				contentMode={contentMode}
				contentType={contentType}
				spaceKey={spaceKey || ''}
				sessionId={suggestedUserQuerySessionId}
				onShareClick={handleShareClick}
				testId={triggerId}
			/>
		) : (
			<PresenceMenu
				contentId={contentId}
				contentMode={contentMode}
				contentType={contentType}
				onLoadMoreClick={onLoadMoreClick}
				totalParticipants={totalParticipants}
				participants={participants}
				analyticSource={analyticSource || ''}
				testId={triggerId}
			/>
		);

		const overflowParticipantCount = totalParticipants - MAX_GROUP_SIZE + 1;

		const landmarkRegionLabel =
			contentType === 'blogpost'
				? formatMessage(i18n.blogpostLandmark)
				: formatMessage(i18n.pageLandmark);

		const isEditObjectHeader =
			fg('confluence_frontend_object_header') && (contentMode === 'edit' || contentMode === 'live');

		return (
			<>
				{participants.length === 1 && isShareDialogOpen && (
					<ShareAndRestrictDialogController
						contentId={contentId}
						contentType={contentType}
						allowRestrictions
						pageModes="view"
						refetchButtonData={() => {}}
						onClose={() => {
							setIsShareDialogOpen(false);
						}}
						isUnpublishedDraft={false}
					/>
				)}
				<Popup
					isOpen={isPopupOpen}
					onClose={() => setIsPopupOpen(false)}
					placement="bottom-end"
					content={() => <Box xcss={popupContainerStyles}>{PopupContent}</Box>}
					trigger={(triggerProps) => (
						<Box
							ref={ref}
							paddingInline="space.050"
							paddingBlock="space.025"
							aria-label={landmarkRegionLabel}
							role="region"
							xcss={isEditObjectHeader && editObjectHeaderStyles}
						>
							<AvatarGroup
								appearance="stack"
								size="small"
								maxCount={MAX_GROUP_SIZE}
								data={participants}
								testId="presence-avatar-group"
								label={landmarkRegionLabel}
								borderColor={token('color.background.neutral')}
								onMoreClick={(e) => onMoreClick(e, overflowParticipantCount)}
								showMoreButtonProps={{ ...triggerProps }}
								shouldPopupRenderToParent={false}
								isTooltipDisabled
								overrides={{
									Avatar: {
										render: (_, props, index) => {
											const participant = participants[index];
											const { id } = participant;

											return (
												<Box padding="space.025" key={`box-${id}`}>
													<PresenceAvatar
														{...props}
														buttonProps={{ ...triggerProps }}
														src={props.src}
														name={props.name}
														activity={participant.visibleActivity}
														contentId={contentId}
														contentMode={contentMode}
														contentType={contentType}
														analyticSource={analyticSource || ''}
														avatarSource="presenceAvatarGroup"
														key={`avatar-${id}`}
														size="small"
														textColor={
															index === 0
																? getCurrentUserColors().textColor
																: getParticipantColors(id).textColor
														}
														borderColor={
															index === 0
																? getCurrentUserColors().backgroundColor
																: getParticipantColors(id).backgroundColor
														}
														stackIndex={MAX_GROUP_SIZE - index}
														testId={`team-presence-avatar-${index}`}
														onAvatarClick={
															isSingleParticipant ? toggleEmptyPresenceDialog : undefined
														}
													/>
												</Box>
											);
										},
									},
									MoreIndicator: {
										render: (_, props) => (
											<Pressable
												type="submit"
												{...props.buttonProps}
												{...props}
												padding="space.025"
												onClick={props.onClick}
												xcss={[
													indicatorStyles,
													overflowParticipantCount > 100 && indicatorOverflowStyles,
												]}
												testId={props.testId}
												aria-label={formatMessage(i18n.overflowLabel)}
											>
												<Box xcss={[overflowMoreStyles, isPopupOpen && moreActiveStyles]}>
													{overflowParticipantCount}
												</Box>
											</Pressable>
										),
									},
								}}
							/>
						</Box>
					)}
				/>
			</>
		);
	},
);
