import React, { useEffect, useContext, useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { MenuGroup, Section, ButtonItem } from '@atlaskit/menu';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import {
	ExperienceTrackerContext,
	TEAM_PRESENCE_AVATAR_GROUP_MENU_VIEW_EXPERIENCE,
} from '@confluence/experience-tracker';

import type { Participant } from '../presence-store';
import type { ContentMode } from '../presenceTypes';

import { PresenceMenuItem } from './PresenceMenuItem';

const i18n = defineMessages({
	activeNowTitle: {
		id: 'team-presence.menu.active.title',
		defaultMessage:
			'{count, plural, =0 {Active now ({count})} one {Active now ({count})} other {Active now ({count})}}',
		description:
			'The text is shown as a title of a section inside of a menu. It describes the number of users actively on the page. {count} will be a literal number, like 0, 1, 2, 3, etc...',
	},
	viewingNowTitle: {
		id: 'team-presence.menu.viewing.title',
		defaultMessage:
			'{count, plural, =0 {Viewing now ({count})} one {Viewing now ({count})} other {Viewing now ({count})}}',
		description:
			'The text is shown as a title of a section inside of a menu. It describes the number of users actively viewing the page. {count} will be a literal number, like 0, 1, 2, 3, etc...',
	},
	editingNowTitle: {
		id: 'team-presence.menu.editing.title',
		defaultMessage:
			'{count, plural, =0 {Editing now ({count})} one {Editing now ({count})} other {Editing now ({count})}}',
		description:
			'The text is shown as a title of a section inside of a menu. It describes the number of users actively editing on the page. {count} will be a literal number, like 0, 1, 2, 3, etc...',
	},
	commenting: {
		id: 'team-presence.menu.commenting',
		defaultMessage: 'Commenting',
		description:
			"This text is shown to describe a user's current action. The person is currently commenting on the page",
	},
	you: {
		id: 'team-presence.menu.you',
		defaultMessage: 'You',
		description: 'This text is shown to indicate the user is you. You are the user',
	},
	loadMore: {
		id: 'team-presence.menu.load-more.non-final',
		defaultMessage: 'Load More',
		description: 'Load more text',
	},
});

export type PresenceMenuProps = {
	participants: Participant[];
	contentMode: ContentMode;
	contentId?: string;
	contentType?: string;
	analyticSource?: string;
	testId?: string;
	onLoadMoreClick(): void;
	totalParticipants: number;
};

export const PresenceMenu = ({
	participants,
	contentMode,
	testId,
	contentId,
	contentType,
	analyticSource,
	onLoadMoreClick,
	totalParticipants,
}: PresenceMenuProps) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const experienceTracker = useContext(ExperienceTrackerContext);

	useEffect(() => {
		createAnalyticsEvent({
			type: 'sendScreenEvent',
			data: {
				name: 'presenceMenu',
				attributes: {
					contentId,
					contentType,
					contentMode,
				},
			},
		}).fire();
	}, [createAnalyticsEvent, contentId, contentType, contentMode]);

	useEffect(() => {
		experienceTracker.succeed({
			name: TEAM_PRESENCE_AVATAR_GROUP_MENU_VIEW_EXPERIENCE,
		});
	}, [experienceTracker]);

	const getHeaderTitle = useCallback(
		(count: number) => {
			if (contentMode === 'edit' && (contentType === 'page' || contentType === 'blogpost')) {
				return formatMessage(i18n.editingNowTitle, { count });
			} else if (contentMode === 'view' && (contentType === 'page' || contentType === 'blogpost')) {
				return formatMessage(i18n.viewingNowTitle, { count });
			} else {
				// contentType === 'live'
				return formatMessage(i18n.activeNowTitle, { count });
			}
		},
		[contentMode, contentType, formatMessage],
	);

	const handleOnLoadMoreClicked = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'presenceMenuLoadMore',
				source: 'presenceMenu',
				attributes: {
					participantsHydrated: participants.length,
					totalParticipants,
				},
			},
		}).fire();

		onLoadMoreClick();
	}, [createAnalyticsEvent, onLoadMoreClick, participants, totalParticipants]);

	return (
		participants && (
			<MenuGroup maxHeight={480} minWidth={360} maxWidth={360} data-testid={testId}>
				<Section
					isScrollable
					title={getHeaderTitle(totalParticipants)}
					testId="presence-menu-group"
				>
					{participants.map((participant: Participant, index) => (
						<PresenceMenuItem
							key={`menu-item-${participant.id.slice(6)}`}
							participant={participant}
							contentMode={contentMode}
							contentId={contentId}
							contentType={contentType}
							analyticSource={analyticSource}
							isCurrentUser={index === 0}
						/>
					))}
					{totalParticipants !== participants.length && (
						<ButtonItem onClick={handleOnLoadMoreClicked}>
							{formatMessage(i18n.loadMore)}
						</ButtonItem>
					)}
				</Section>
			</MenuGroup>
		)
	);
};
