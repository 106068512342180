import gql from 'graphql-tag';

// DO NOT RENAME PresenceProviderUserQuery since it's explicitly rate limited here
// https://bitbucket.org/atlassian/cc-graphql/src/02fa5eca4e936c55c8c8dbee0dbb28e2be1cc3ce/sliver/archetype-descriptor-template.yml#lines-260:319
export const PresenceProviderUserQuery = gql`
	query PresenceProviderUserQuery($accountIds: [String]) {
		confluenceUsers(accountIds: $accountIds) {
			nodes {
				profilePicture {
					isDefault
					path(type: RELATIVE)
				}
				... on KnownUser {
					accountId
				}
				... on UnknownUser {
					accountId
				}
				displayName
				permissionType
			}
		}
	}
`;
