import { type MutableRefObject } from 'react';
import {
	createHook,
	createActionsHook,
	createStateHook,
	createStore,
	createContainer,
} from 'react-sweet-state';

import type { PresenceActivity, ProviderParticipant } from '@atlaskit/editor-common/collab';

import type { Participant, PresenceSessionData } from './';

import { SitePermissionType } from '../presence/__types__/PresenceProviderUserQuery';
import { getCurrentUserFromMap, CURRENT_USER_DUMMY_SESSION_ID } from '../presenceUtils';
import type { PresenceUser } from '../presenceTypes';

export type ParticipantsToHydrate = {
	sessions: PresenceSessionData;
} & Omit<ProviderParticipant, 'sessionId'>;

const updateParticipantSession = (
	activity: PresenceActivity | undefined,
	participant: Participant,
	sessionId: string,
) => {
	return {
		...participant,
		sessions: {
			...participant.sessions,
			[sessionId]: {
				activity: activity ?? 'viewer',
			},
		},
	};
};

const mapAnonymousParticipant = (
	presenceId: string,
	activity: PresenceActivity | undefined,
	sessions: PresenceSessionData,
): Participant => {
	return {
		id: presenceId,
		userId: 'unidentified',
		presenceId,
		name: '',
		src: '',
		isGuest: false,
		visibleActivity: activity ?? 'viewer',
		sessions,
	};
};

// yes, all this is hella messy right now, will be refactoring and moving out of a store
const PresenceStore = createStore({
	initialState: {
		participants: new Map<string, Participant>(),
		totalCount: 0,
	},
	actions: {
		updateSessionData:
			(props: { id: string; sessionId: string; presenceActivity?: PresenceActivity }) =>
			({ getState, setState }) => {
				const { id, sessionId, presenceActivity: activity } = props;
				const { participants } = getState();
				const participant = participants.get(id);

				if (participant) {
					const participantMap = new Map(participants);
					participantMap.set(id, updateParticipantSession(activity, participant, sessionId));
					setState({ participants: participantMap });
				}
			},
		addParticipantFromCollabSocket:
			(participant: ProviderParticipant) =>
			({ getState, setState }) => {
				const { participants, totalCount } = getState();

				// can't be null in confluence, ultimately is the userId for logged in users and presenceId for anonymous
				const { presenceId: id } = participant;
				const participantMap = new Map(participants);

				if (id) {
					const previousParticipant = participantMap.get(id);

					const entry: Participant = {
						...participant,
						// need to determine if entry
						isCurrentUser: previousParticipant?.isCurrentUser ?? false,
						id,
						src: participant.avatar,
						sessions: {
							...previousParticipant?.sessions,
							[participant.sessionId]: {
								activity: participant.presenceActivity ?? 'editor',
							},
						},
						// doesn't matter, the view code will determine what visible activity is. Main reasoning was not wanting to create a new
						// ProviderParticipant type at the time since it's used a lot. Can cleanup later
						visibleActivity: 'editor',
					};

					participantMap.set(id, entry);
					if (!previousParticipant) {
						setState({ participants: participantMap, totalCount: totalCount + 1 });
					} else {
						setState({ participants: participantMap });
					}
				}
			},
		addParticipant:
			(
				participant: ProviderParticipant,
				participantsToHydrateRef: MutableRefObject<Record<string, ParticipantsToHydrate>>,
			) =>
			({ getState, setState }) => {
				const { participants, totalCount } = getState();

				const joinedParticipant = participant;
				const key = joinedParticipant.presenceId ?? joinedParticipant.userId;
				const hydratedParticipant = participants.get(key);
				if (hydratedParticipant) {
					const participantMap = new Map(participants);
					participantMap.set(
						key,
						updateParticipantSession(
							joinedParticipant.presenceActivity,
							hydratedParticipant,
							joinedParticipant.sessionId,
						),
					);
					setState({ participants: participantMap });
				} else {
					const { sessionId, presenceActivity: activity, userId } = joinedParticipant;

					const previousEntry = participantsToHydrateRef.current[key];

					const entry = { [sessionId]: { activity: activity ?? 'viewer' } };

					const hasPreviousEntry = !!previousEntry?.sessions;

					// we can run into cases where we have multiple sessions for the same user, but have yet to fully hydrate them
					// so ensure our sessions list is up-tp-date
					const sessions: PresenceSessionData = hasPreviousEntry
						? {
								...previousEntry?.sessions,
								...entry,
							}
						: entry;

					participantsToHydrateRef.current[key] = {
						...joinedParticipant,
						sessions,
					};

					if (!hasPreviousEntry) {
						setState({ totalCount: totalCount + 1 });
					}

					// We can skip hydration for anonymous users, perhaps I should bump this higher up to short circuit
					// more of this logic ... this is getting quite messy
					const isAnonymous = userId === 'unidentified';

					if (isAnonymous) {
						const participantMap = new Map(participants);

						participantMap.set(key, mapAnonymousParticipant(key, activity, sessions));

						setState({ participants: participantMap });

						delete participantsToHydrateRef.current[key];
					}
				}
			},
		addHydratedParticipant:
			(
				participant: PresenceUser & { presenceId: string; sessions: PresenceSessionData },
				participantsToHydrateRef: MutableRefObject<Record<string, ParticipantsToHydrate>>,
			) =>
			({ getState, setState }) => {
				const { participants } = getState();

				const user = participant;
				// can't be null in confluence, ultimately is the userId for logged in users and presenceId for anonymous
				const { presenceId: id, sessions } = user;
				const userId = (user as any)?.accountId ?? 'unidentified';

				const participantMap = new Map(participants);

				if (id) {
					const previousParticipant = participantMap.get(id);

					const entry: Participant = {
						id,
						userId,
						src: user.avatarPath,
						isGuest: user.permissionType === SitePermissionType.EXTERNAL ?? undefined,
						sessions,
						presenceId: id,
						name: user.name,
						isCurrentUser: user.isCurrentUser ?? false,
						// doesn't matter, the view code will determine what visible activity is, just doing this for now
						visibleActivity: 'viewer',
					};

					let dirty = false;
					if (!previousParticipant) {
						participantMap.set(id, entry);
						dirty = true;
					}

					// still triggers a state change even if object hasn't changed,
					// so helping out a bit to prevent a extra re-renders
					if (dirty) {
						setState({ participants: participantMap });
					}

					delete participantsToHydrateRef.current[id];
				}
			},
		removeParticipant:
			(
				sessionId: string,
				participantsToHydrateRef: MutableRefObject<Record<string, ParticipantsToHydrate>>,
			) =>
			({ getState, setState }) => {
				const { participants, totalCount } = getState();

				const nonHydrated = Object.keys(participantsToHydrateRef.current).find((key) => {
					return participantsToHydrateRef.current[key].sessions[sessionId];
				});

				if (nonHydrated) {
					delete participantsToHydrateRef.current[nonHydrated];
					setState({ totalCount: totalCount - 1 });
				} else {
					const newParticipants = new Map<string, Participant>(participants);
					const participant = getCurrentUserFromMap(participants, sessionId);

					if (participant) {
						if (Object.keys(participant.sessions).length === 1) {
							newParticipants.delete(participant.id);
							setState({
								participants: newParticipants,
								totalCount: totalCount - 1,
							});
						} else {
							delete participant.sessions[sessionId];
							newParticipants.set(participant.id, participant);
							setState({ participants: newParticipants });
						}
					}
				}
			},
		resetParticipants:
			(currentUserId: string | null) =>
			({ setState, getState }) => {
				const { participants } = getState();
				// current user is always the first entry in the map, and we never want to clear the current user from participants
				const currentUser = currentUserId ? participants.get(currentUserId) : undefined;
				const newParticipants = new Map<string, Participant>();

				if (currentUser && currentUserId) {
					// clear all sessions except the dummy one
					currentUser.sessions = {
						[CURRENT_USER_DUMMY_SESSION_ID]: currentUser.sessions[CURRENT_USER_DUMMY_SESSION_ID],
					};
					newParticipants.set(currentUserId, currentUser);
				}

				setState({ participants: newParticipants, totalCount: newParticipants.size });
			},
	},
	name: 'PresenceStore',
});

export const usePresenceStore = createHook(PresenceStore);
export const usePresenceStoreActions = createActionsHook(PresenceStore);
export const usePresenceStoreState = createStateHook(PresenceStore);

export const PresenceStoreContainer = createContainer(PresenceStore);
