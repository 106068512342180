/**
 * Switches to the share dialog by clicking the 'share-trigger-btn' element.
 *
 * This function attempts to find the element with the ID 'share-trigger-btn'. This is
 * the ID of the legacy share button in Confluence. This is no longer necessary when the
 * new Unified Share Dialog is released (around Jan 2025 - March 2025).
 */
export function switchToShareDialog(closeModal: (callback?: () => void) => void) {
	const node = document.getElementById('share-trigger-btn');

	if (node) {
		closeModal(() => {
			node.click();
		});
	}
}
