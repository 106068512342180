import gql from 'graphql-tag';
import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import { Flex, xcss } from '@atlaskit/primitives';
import Avatar from '@atlaskit/avatar';
import { token } from '@atlaskit/tokens';

import { markErrorAsHandled } from '@confluence/graphql';

const styles = xcss({ paddingInline: 'space.075' });

export const ContentHeaderPresenceAvatarPlaceholder = () => {
	const { data, error } = useQuery(gql`
		query ContentHeaderPresenceAvatarPlaceholderUserQuery {
			user(current: true) {
				id
				displayName
				photos {
					value
					isPrimary
				}
			}
		}
	`);

	if (error) {
		markErrorAsHandled(error);
	}

	return (
		<Flex alignItems="center" xcss={styles}>
			<Avatar
				borderColor={token('color.border.accent.blue')}
				size="small"
				src={data?.user?.photos?.[0]?.value}
				testId="presence-avatar"
			/>
		</Flex>
	);
};
