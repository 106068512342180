import React, { useCallback, useEffect } from 'react';

import ButtonGroup from '@atlaskit/button/button-group';
import { Inline, Box } from '@atlaskit/primitives';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button/new';

import { CopyLinkButton, type PageMode } from '@confluence/copy-content-link';

export type NoCollaboratorsDialogShareOptionsProps = {
	contentId: string;
	contentMode: 'live' | 'edit' | 'view';
	contentType: string;
	pageMode: PageMode;
	spaceKey: string;
	copyLinkButtonLabel: string;
	shareButtonLabel: string;
	isShareButtonDisabled?: boolean;
	onShareClick: (analyticsSource: string) => void;
};

export const NoCollaboratorsDialogShareOptions = ({
	contentId,
	contentMode,
	contentType,
	pageMode,
	spaceKey,
	copyLinkButtonLabel,
	shareButtonLabel,
	isShareButtonDisabled,
	onShareClick,
}: NoCollaboratorsDialogShareOptionsProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onShare = useCallback(
		(_: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
			if (onShareClick) {
				onShareClick('noCollaboratorsDialog');
			}
		},
		[onShareClick],
	);

	useEffect(() => {
		createAnalyticsEvent({
			type: 'sendScreenEvent',
			data: {
				name: 'noCollaboratorsDialogShareOptions',
				attributes: {
					contentMode,
					contentType,
				},
			},
		}).fire();
	}, [createAnalyticsEvent, contentMode, contentType, pageMode]);

	return (
		<Box padding="space.200" paddingBlockStart="space.400">
			<Inline alignInline="end">
				<ButtonGroup>
					<CopyLinkButton
						buttonAppearance="subtle"
						buttonText={copyLinkButtonLabel}
						contentId={contentId}
						contentType={contentType}
						pageMode={pageMode}
						spaceKey={spaceKey}
						source="noCollaboratorsDialog"
						shouldFitContainer
					/>
					<Button
						testId="open-share-dialog"
						appearance="default"
						onClick={onShare}
						isDisabled={isShareButtonDisabled}
					>
						{shareButtonLabel}
					</Button>
				</ButtonGroup>
			</Inline>
		</Box>
	);
};
