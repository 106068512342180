import React, { useEffect, useMemo, useState, useContext } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import {
	SpotlightManager,
	SpotlightPulse,
	SpotlightTarget,
	SpotlightTransition,
} from '@atlaskit/onboarding';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useSetOnboardingState } from '@confluence/onboarding-helpers/entry-points/hooks/useOnboardingState';
import { useChoreographerAssets } from '@confluence/choreographer-services';
import { useIsProductAdmin } from '@confluence/current-user';
import { ONBOARDING_PRESENCE_PAGE_STATE_KEYS } from '@confluence/onboarding-helpers/entry-points/constants/onboarding-state-constants';
import { useLivePageMode } from '@confluence/live-pages-utils/entry-points/useLivePagesStore';
import {
	ExperienceTrackerContext,
	ExperienceFailure,
	TEAM_PRESENCE_AVATAR_GROUP_VIEW_EXPERIENCE,
} from '@confluence/experience-tracker';

import { PresenceAvatarGroup } from '../presence-avatar-group';
import { type PresenceSessionData, usePresenceStoreState } from '../presence-store';
import type { ContentMode } from '../presenceTypes';
import { getCurrentUserFromMap } from '../presenceUtils';
import { useIsSpotlightEligible } from '../useIsSpotlightEligible';

import spotlightImage from './assets/images/presence-spotlight-img.png';
import { usePresenceProvider } from './usePresenceProvider';
import { ContentHeaderPresenceAvatarPlaceholder } from './ContentHeaderPresenceAvatarPlaceholder';

export const PRESENCE_LEARN_MORE_LINK =
	'https://support.atlassian.com/confluence-cloud/docs/view-a-page/';

const i18n = defineMessages({
	spotlightTitle: {
		id: 'team-presence.avatar-group.spotlight.title',
		defaultMessage:
			'{contentType, select, page {See who’s on your page} blogpost {See who’s on your blog post} other {See who’s on your content}}',
		description:
			'Title for a changeboarding spotlight that appears when user first enters a page to teach them how to see who is viewing or editing the page. {contentType} is the contents type',
	},
	spotlightDescriptionForUsers: {
		id: 'team-presence.avatar-group.spotlight.description-user',
		defaultMessage:
			'{contentType, select, page {You can now see who’s viewing this page in real time.} blogpost {You can now see who’s viewing this blog post in real time.} other {You can now see who’s viewing this content in real time.}}',
		description:
			'Description for a changeboarding spotlight shown to users that appears when user first enters a page to teach them how to see who is viewing or editing the page. {contentType} is the contents type',
	},
	spotlightDescriptionForAdmin: {
		id: 'team-presence.avatar-group.spotlight.description-admin',
		defaultMessage:
			'{contentType, select, page {Your team can now see who’s viewing this page in real time.} blogpost {Your team can now see who’s viewing this blog post in real time.} other {Your team can now see who’s viewing this content in real time.}}',
		description:
			'Description for a changeboarding spotlight that appears when user first enters a page to teach them how to see who is viewing or editing the page. {contentType} is the contents type',
	},
	spotlightAccessibleLabel: {
		id: 'team-presence.avatar-group.spotlight.label',
		defaultMessage: 'Onboarding for real-time presence avatar group',
		description:
			'Accessible label for a changeboarding spotlight that appears when user first enters a page to teach them how to see who is viewing or editing the page',
	},
	spotlightPrimaryAction: {
		id: 'team-presence.avatar-group.spotlight.primary-action',
		defaultMessage: 'OK',
		description:
			'Button text for a primary action on a changeboarding spotlight to acknowledge that you read the spotlight',
	},
	spotlightSecondaryActionForUsers: {
		id: 'team-presence.avatar-group.spotlight.secondary-action-user',
		defaultMessage: 'More about visibility',
		description:
			'Button text for a secondary action on a changeboarding spotlight showed to licensed users to learn more about real-time presence feature',
	},
	spotlightSecondaryActionForAdmin: {
		id: 'team-presence.avatar-group.spotlight.secondary-action-admin',
		defaultMessage: 'More about settings',
		description:
			'Button text for a secondary action on a changeboarding spotlight showed to admins to learn more about real-time presence feature',
	},
	anonymousUser: {
		id: 'team-presence.avatar-group.anonymous-user',
		defaultMessage: 'Anonymous Person',
		description:
			'The default user name provided for anonymous users when displayed in the avatar group within the real-time presence experience',
	},
});

const PRESENCE_AVATAR_GROUP_ANALYTIC_SOURCE = 'presenceAvatarGroup';

const getActivityToDisplay = (sessionData: PresenceSessionData, currentUserSessionId?: string) => {
	if (currentUserSessionId) {
		return sessionData[currentUserSessionId].activity;
	} else {
		const hasEditor = Object.keys(sessionData).find(
			(sessionId) => sessionData[sessionId]?.activity === 'editor',
		);
		return hasEditor ? 'editor' : 'viewer';
	}
};

export type ContentHeaderPresenceContainerProps = {
	contentId: string;
	contentMode: ContentMode;
	contentType: string;
	analyticSource?: string;
};

export const ContentHeaderPresenceContainer = ({
	contentId,
	contentMode,
	contentType,
	analyticSource,
}: ContentHeaderPresenceContainerProps) => {
	const { isSpotlightEligible, onboardingStateError, onboardingStateLoading } =
		useIsSpotlightEligible();

	const { setOnboardingState } = useSetOnboardingState();
	const experienceTracker = useContext(ExperienceTrackerContext);

	const [isPulseActive, setIsPulseActive] = useState(false);
	const [isSpotlightActive, setIsSpotlightActive] = useState(false);

	const { Spotlight } = useChoreographerAssets();

	const { isProductAdmin } = useIsProductAdmin();
	const [{ isEditMode: isLiveEditMode }] = useLivePageMode();

	let activity: 'viewer' | 'editor';

	if (contentMode === 'edit') {
		activity = 'editor';
	} else if (contentMode === 'live') {
		activity = isLiveEditMode ? 'editor' : 'viewer';
	} else {
		activity = 'viewer';
	}

	const {
		sessionId: providerSessionId,
		fetchMore,
		networkError,
	} = usePresenceProvider(contentId, contentType ?? '', contentMode, activity);

	const { participants, totalCount: totalParticipants } = usePresenceStoreState();

	const { formatMessage } = useIntl();

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const avatarGroupRef = React.useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (totalParticipants < 0) {
			experienceTracker.fail({
				name: TEAM_PRESENCE_AVATAR_GROUP_VIEW_EXPERIENCE,
				error: new Error('Total participant count is less than 0'),
				attributes: {
					totalCount: totalParticipants,
				},
			});
		}
	}, [totalParticipants, experienceTracker]);

	/*
	 * Memoize the participants to ensure that we only show unique viewers and editors
	 */
	const memoizedParticipants = useMemo(() => {
		const participantValues = [...participants.values()];

		const currentUser = getCurrentUserFromMap(participants, providerSessionId);

		return participantValues.map((participant) => {
			return {
				...participant,
				name:
					participant.userId === 'unidentified'
						? formatMessage(i18n.anonymousUser)
						: participant.name,
				visibleActivity:
					participant.isCurrentUser && currentUser
						? getActivityToDisplay(participant.sessions, providerSessionId)
						: getActivityToDisplay(participant.sessions),
			};
		});
	}, [participants, providerSessionId, formatMessage]);

	const onPresenceSpotlightClose = (shouldSendAnalytics: boolean = false) => {
		void setOnboardingState({
			key: ONBOARDING_PRESENCE_PAGE_STATE_KEYS.PRESENCE_AVATAR_GROUP_PAGE_SPOTLIGHT_SEEN,
			value: 'true',
		});
		// we only want to send analytics when the user actively clicked on close
		if (shouldSendAnalytics) {
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					source: `${PRESENCE_AVATAR_GROUP_ANALYTIC_SOURCE}Spotlight`,
					action: 'clicked',
					actionSubject: 'button',
					actionSubjectId: 'presenceSpotlightClose',
					attributes: {
						isProductAdmin,
					},
				},
			}).fire();
		}
		setIsSpotlightActive(false);
	};

	const onLearnMore = () => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: `${PRESENCE_AVATAR_GROUP_ANALYTIC_SOURCE}Spotlight`,
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'presenceSpotlightLearnMore',
				attributes: {
					isProductAdmin,
				},
			},
		}).fire();
		onPresenceSpotlightClose(false);
	};

	const onPulseClick = () => {
		if (!isPulseActive) {
			return;
		}

		setIsPulseActive(false);
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: `${PRESENCE_AVATAR_GROUP_ANALYTIC_SOURCE}Pulse`,
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'presencePulse',
				attributes: {
					isProductAdmin,
				},
			},
		}).fire();

		setIsSpotlightActive(true);
		createAnalyticsEvent({
			type: 'sendTrackEvent',
			data: {
				source: PRESENCE_AVATAR_GROUP_ANALYTIC_SOURCE,
				actionSubject: 'PresenceAvatarGroup',
				action: 'spotlight shown',
				attributes: {
					contentId,
					contentMode,
					contentType,
				},
			},
		}).fire();
	};

	useEffect(() => {
		if (!onboardingStateLoading && !onboardingStateError) {
			const shouldPulseSpotlight =
				isSpotlightEligible && contentMode === 'view' && (isProductAdmin || totalParticipants > 1);

			if (shouldPulseSpotlight) {
				setIsPulseActive(true);
				createAnalyticsEvent({
					type: 'sendTrackEvent',
					data: {
						source: PRESENCE_AVATAR_GROUP_ANALYTIC_SOURCE,
						actionSubject: 'PresenceAvatarGroup',
						action: 'pulse shown',
						attributes: {
							contentId,
							contentMode,
							contentType,
						},
					},
				}).fire();
			}
		} else if (onboardingStateError && !onboardingStateLoading) {
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					source: PRESENCE_AVATAR_GROUP_ANALYTIC_SOURCE,
					actionSubject: 'PresenceAvatarGroup',
					action: 'pulse failed',
					attributes: {
						contentId,
						contentMode,
						contentType,
					},
				},
			}).fire();
		}
	}, [
		contentId,
		contentMode,
		contentType,
		isProductAdmin,
		isSpotlightEligible,
		totalParticipants,
		onboardingStateLoading,
		onboardingStateError,
		createAnalyticsEvent,
	]);

	if (networkError) {
		return (
			<ExperienceFailure name={TEAM_PRESENCE_AVATAR_GROUP_VIEW_EXPERIENCE} error={networkError} />
		);
	}

	if (!memoizedParticipants?.length) {
		return <ContentHeaderPresenceAvatarPlaceholder />;
	}

	let spotlightDescription = formatMessage(i18n.spotlightDescriptionForUsers, {
		contentType,
	});

	let learnMoreButtonText = formatMessage(i18n.spotlightSecondaryActionForUsers);

	if (isProductAdmin) {
		spotlightDescription = formatMessage(i18n.spotlightDescriptionForAdmin, {
			contentType,
		});
		learnMoreButtonText = formatMessage(i18n.spotlightSecondaryActionForAdmin);
	}

	return (
		<SpotlightManager blanketIsTinted={false}>
			<SpotlightTarget name="presence-avatar">
				<SpotlightPulse
					pulse={isPulseActive}
					radius={3}
					onClick={onPulseClick}
					testId="presence-pulse"
				>
					<PresenceAvatarGroup
						contentId={contentId}
						contentMode={contentMode}
						contentType={contentType}
						participants={memoizedParticipants}
						analyticSource={analyticSource}
						onLoadMoreClick={fetchMore}
						totalParticipants={totalParticipants}
						ref={avatarGroupRef}
					/>
				</SpotlightPulse>
			</SpotlightTarget>

			<SpotlightTransition>
				{isSpotlightActive && (
					<Spotlight
						messageId="presence-avatar-spotlight"
						messageType="engagement"
						image={spotlightImage}
						actions={[
							{
								onClick: () => onPresenceSpotlightClose(true),
								text: formatMessage(i18n.spotlightPrimaryAction),
								testId: 'presence-spotlight-action-ok',
							},
							{
								onClick: onLearnMore,
								text: learnMoreButtonText,
								testId: 'presence-spotlight-action-learn-more',
								href: PRESENCE_LEARN_MORE_LINK,
								target: '_blank',
							},
						]}
						heading={formatMessage(i18n.spotlightTitle, {
							contentType,
						})}
						target="presence-avatar"
						label={formatMessage(i18n.spotlightAccessibleLabel)}
						key="presence-avatar-spotlight"
						testId="presence-avatar-spotlight"
						targetRadius={3}
						dialogPlacement="bottom right"
						shouldWatchTarget
					>
						{spotlightDescription}
					</Spotlight>
				)}
			</SpotlightTransition>
		</SpotlightManager>
	);
};
