import { LoadableLazy } from '@confluence/loadable';

export const ShareAndRestrictDialogController = LoadableLazy<any>({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ShareAndRestrictDialogController" */ '../src/v2/controller/Controller'
			)
		).Controller,
});
