import { useEffect, useRef } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

import type { PresenceUser } from '../presenceTypes';

import { SitePermissionType } from './__types__/PresenceProviderUserQuery';

type Props = {
	isGuest: boolean;
	isAnonymous: boolean;
	onComplete(result: PresenceUser): void;
	onError(error: any): void;
};

const GetPresenceCurrentUserQuery = gql`
	query usePresenceCurrentUserQuery {
		user(current: true) {
			id
			displayName
			photos {
				value
				isPrimary
			}
		}
	}
`;

export const usePresenceCurrentUser = ({ isGuest, isAnonymous, onComplete, onError }: Props) => {
	const hasBeenCalledRef = useRef(false);
	// while most of this is accessible from useSessionData, photos (which is the avatar) is not, hence why we use this query
	const { data: userProfileData, error } = useQuery(GetPresenceCurrentUserQuery, {
		skip: isAnonymous,
	});

	useEffect(() => {
		if (error) {
			onError(error);
		}
	}, [error, onError]);

	// TODO address later
	// useEffect(() => {
	// 	if (isAnonymous) {
	// 		onComplete({
	// 			accountId: 'unidentified',
	// 			name: '',
	// 			avatarPath: '',
	// 			isCurrentUser: true,
	// 		});
	// 	}
	// }, [isAnonymous, onComplete]);

	useEffect(() => {
		if (!isAnonymous && !hasBeenCalledRef.current && userProfileData?.user) {
			const { user } = userProfileData;

			onComplete({
				accountId: user.id,
				name: user.displayName ?? '',
				avatarPath: user.photos?.[0]?.value ?? '',
				permissionType: isGuest ? SitePermissionType.EXTERNAL : undefined,
				isCurrentUser: true,
			});

			hasBeenCalledRef.current = true;
		}
	}, [isAnonymous, userProfileData, isGuest, onComplete]);

	return {};
};
