import React from 'react';

import { Flex } from '@atlaskit/primitives';

type EditIconProps = {
	testId?: string;
};

/*
 Added custom SVG here because the atlaskit icon doesn't allow custom styling to make it smaller than 16px,
 but since this is for a badge, we really need it smaller!
 I made this similar to confluence/platform/packages/design-system/icon/src/components/icon-new.tsx
 Also, a note that this simple pencil icon may/will be deprecated.
*/
export const EditIcon = ({ testId }: EditIconProps) => (
	<Flex testId={testId} alignItems="center" role="img">
		<svg width="10" height="10" viewBox="0 0 24 24">
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M4.02 19.23a1 1 0 0 0 1.18 1.18l3.81-.78-4.21-4.21zM19.84 6.71l-2.12-2.12A2 2 0 0 0 16.31 4c-.51 0-1.02.2-1.41.59l-9.76 9.76 4.95 4.95 9.76-9.76a2 2 0 0 0 0-2.83"
			/>
		</svg>
	</Flex>
);
