import React, { useEffect, useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { Attribution, ErrorBoundary } from '@confluence/error-boundary';
import { fg } from '@confluence/feature-gating';
import { useIsCurrentPageLive } from '@confluence/live-pages-utils/entry-points/useIsCurrentPageLive';
import { NativeCollabPresenceStateContainer } from '@confluence/native-collab';
import { useIsEditorPage } from '@confluence/route-manager/entry-points/useIsEditorPage';
import {
	ExperienceTrackerContext,
	TEAM_PRESENCE_AVATAR_GROUP_VIEW_EXPERIENCE,
} from '@confluence/experience-tracker';
import { useSessionData } from '@confluence/session-data';
import { useSpaceKey } from '@confluence/space-utils';
import { markErrorAsHandled } from '@confluence/graphql';

import { PresenceStoreContainer } from '../presence-store';
import type { ContentMode } from '../presenceTypes';

import type {
	PresenceSettingsQuery as PresenceSettingsQueryType,
	PresenceSettingsQueryVariables,
} from './__types__/PresenceSettingsQuery';
import { ContentHeaderPresenceContainer } from './ContentHeaderPresenceContainer';
import { PresenceSettingsQuery } from './PresenceSettingsQuery.graphql';

export type ContentHeaderPresenceProps = {
	contentId: string;
	contentType: string | null | undefined;
	analyticSource?: string;
};

export const ContentHeaderPresenceComponent = ({
	contentId,
	contentType,
	analyticSource,
}: ContentHeaderPresenceProps) => {
	const experienceTracker = useContext(ExperienceTrackerContext);

	const { cloudId, isLoggedIn } = useSessionData();
	const spaceKey = useSpaceKey();

	const isLivePage = useIsCurrentPageLive();
	const isEditPage = useIsEditorPage();

	let contentMode: ContentMode = 'view';

	if (isLivePage) {
		contentMode = 'live';
	} else if (isEditPage) {
		contentMode = 'edit';
	}

	const { data, error } = useQuery<PresenceSettingsQueryType, PresenceSettingsQueryVariables>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		PresenceSettingsQuery,
		{
			variables: {
				cloudId,
				spaceKey: spaceKey ?? '',
			},
			skip: !fg('confluence_team_presence_enable_on_view') || contentMode !== 'view',
		},
	);

	useEffect(() => {
		experienceTracker.start({
			name: TEAM_PRESENCE_AVATAR_GROUP_VIEW_EXPERIENCE,
		});
	}, [experienceTracker]);

	const isSettingEnabledOnView =
		data?.confluence_teamPresenceContentSetting?.isEnabledOnContentView === true;

	const isAnonymousBlockedOnView =
		!isLoggedIn && fg('confluence_team_presence_prevent_anonymous_on_view');

	if (!contentType) {
		return null;
	}

	if (error) {
		experienceTracker.fail({
			name: TEAM_PRESENCE_AVATAR_GROUP_VIEW_EXPERIENCE,
			error,
		});

		markErrorAsHandled(error);

		return null;
	}

	if (contentMode === 'view') {
		if (!isSettingEnabledOnView) {
			experienceTracker.abort({
				name: TEAM_PRESENCE_AVATAR_GROUP_VIEW_EXPERIENCE,
				reason: `Real-time visibility is disabled on view mode`,
			});

			return null;
		}

		if (isAnonymousBlockedOnView) {
			experienceTracker.abort({
				name: TEAM_PRESENCE_AVATAR_GROUP_VIEW_EXPERIENCE,
				reason: `Real-time visibility is disabled on view mode for anonymous users`,
			});

			return null;
		}
	}

	return (
		<NativeCollabPresenceStateContainer scope={contentId}>
			<PresenceStoreContainer>
				<ContentHeaderPresenceContainer
					contentId={contentId}
					contentMode={contentMode}
					contentType={contentType}
					analyticSource={analyticSource}
				/>
			</PresenceStoreContainer>
		</NativeCollabPresenceStateContainer>
	);
};

export const ContentHeaderPresence = (props: ContentHeaderPresenceProps) => {
	return (
		<ErrorBoundary attribution={Attribution.EXTERNAL_COLLAB}>
			<ContentHeaderPresenceComponent {...props} />
		</ErrorBoundary>
	);
};
