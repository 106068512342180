import React, { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import Lozenge from '@atlaskit/lozenge';
import { ButtonItem } from '@atlaskit/menu';
import { Flex } from '@atlaskit/primitives';

import { ExternalUserLozenge } from '@confluence/external-collab-ui/entry-points/ExternalUserLozenge';

import { getParticipantColors, getCurrentUserColors } from '../presenceUtils';
import type { Participant } from '../presence-store';

import { PresenceAvatar } from './PresenceAvatar';

const i18n = defineMessages({
	editing: {
		id: 'team-presence.menu.editing',
		defaultMessage: 'Editing',
		description:
			'The text is shown as a title of a section inside of a menu. The people in the section are editing a page',
	},
	viewing: {
		id: 'team-presence.menu.viewing',
		defaultMessage: 'Viewing',
		description: 'The text is used to indicate that the current user is `Viewing the page`',
	},
	you: {
		id: 'team-presence.menu.current-user.marker',
		defaultMessage: 'You',
		description: 'The text is used to indicate that the current user is `You`',
	},
});

export type PresenceMenuItemProps = {
	participant: Participant;
	contentMode: 'live' | 'edit' | 'view';
	contentId?: string;
	contentType?: string;
	analyticSource?: string;
	isCurrentUser?: boolean;
};

export const PresenceMenuItem = ({
	participant,
	contentMode,
	contentId,
	contentType,
	analyticSource,
	isCurrentUser,
}: PresenceMenuItemProps) => {
	const { formatMessage } = useIntl();

	const activityText =
		participant.visibleActivity === 'editor'
			? formatMessage(i18n.editing)
			: formatMessage(i18n.viewing);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleClick = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				product: 'confluence',
				source: 'presenceMenu',
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'presenceMenuItem',
				attributes: {
					contentId,
					contentType,
					contentMode,
				},
			},
		}).fire();
	}, [createAnalyticsEvent, contentId, contentType, contentMode]);

	const isAnonymous = participant.userId === 'unidentified';

	const { id } = participant;

	return (
		<ButtonItem
			onClick={handleClick}
			testId="team-presence-menu-item"
			iconBefore={
				<PresenceAvatar
					size="small"
					name={participant.name}
					src={participant.src}
					testId="team-presence-menu-item-avatar"
					textColor={
						isCurrentUser ? getCurrentUserColors().textColor : getParticipantColors(id).textColor
					}
					borderColor={
						isCurrentUser
							? getCurrentUserColors().backgroundColor
							: getParticipantColors(id).backgroundColor
					}
					activity={participant.visibleActivity ?? 'viewer'}
					contentMode={contentMode}
					contentId={contentId || ''}
					contentType={contentType || ''}
					analyticSource={analyticSource || ''}
					avatarSource="presenceMenu"
					isPresenceMenu
				/>
			}
			description={activityText}
		>
			<Flex alignItems="baseline" gap="space.050">
				{participant.name}
				{participant.isGuest && <ExternalUserLozenge />}
				{isCurrentUser && isAnonymous && (
					<Lozenge appearance="inprogress">{formatMessage(i18n.you)}</Lozenge>
				)}
			</Flex>
		</ButtonItem>
	);
};
