import React, { useState } from 'react';

import Avatar from '@atlaskit/avatar';
import Button from '@atlaskit/button/new';
import { CustomItem } from '@atlaskit/menu';
import { Box, Flex, xcss } from '@atlaskit/primitives';

import type { SuggestedUser } from './NoCollaboratorsDialog';

const FlexTruncateStyles = xcss({ flexGrow: '1', minWidth: '0' });

export type NoCollaboratorsDialogShareSuggestionMenuItemProps = {
	suggestion: SuggestedUser;
	shareButtonLabelBeforeClick: string;
	shareButtonLabelAfterClick: string;
	onHandleShare: () => void;
};

export const NoCollaboratorsDialogShareSuggestionMenuItem = ({
	suggestion,
	shareButtonLabelBeforeClick,
	shareButtonLabelAfterClick,
	onHandleShare,
}: NoCollaboratorsDialogShareSuggestionMenuItemProps) => {
	const [hasShared, setHasShared] = useState<boolean>(false);

	const shareButtonText = hasShared ? shareButtonLabelAfterClick : shareButtonLabelBeforeClick;

	const handleOnShareClicked = () => {
		setHasShared(true);

		onHandleShare();
	};

	return (
		<CustomItem
			component={() => (
				<Box paddingInline="space.200" paddingBlock="space.100">
					<Flex gap="space.150" alignItems="center">
						<Avatar size="small" src={suggestion.picture} />
						<Box xcss={FlexTruncateStyles}>{suggestion.name}</Box>
						<Button
							testId="share-with-people"
							isDisabled={hasShared}
							onClick={handleOnShareClicked}
						>
							{shareButtonText}
						</Button>
					</Flex>
				</Box>
			)}
		/>
	);
};
