/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum SitePermissionType {
  ANONYMOUS = "ANONYMOUS",
  APP = "APP",
  EXTERNAL = "EXTERNAL",
  INTERNAL = "INTERNAL",
  JSD = "JSD",
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PresenceProviderUserQuery
// ====================================================

export interface PresenceProviderUserQuery_confluenceUsers_nodes_Anonymous_profilePicture {
  isDefault: boolean | null;
  path: string;
}

export interface PresenceProviderUserQuery_confluenceUsers_nodes_Anonymous {
  profilePicture: PresenceProviderUserQuery_confluenceUsers_nodes_Anonymous_profilePicture | null;
  displayName: string | null;
  permissionType: SitePermissionType | null;
}

export interface PresenceProviderUserQuery_confluenceUsers_nodes_KnownUser_profilePicture {
  isDefault: boolean | null;
  path: string;
}

export interface PresenceProviderUserQuery_confluenceUsers_nodes_KnownUser {
  profilePicture: PresenceProviderUserQuery_confluenceUsers_nodes_KnownUser_profilePicture | null;
  accountId: string | null;
  displayName: string | null;
  permissionType: SitePermissionType | null;
}

export interface PresenceProviderUserQuery_confluenceUsers_nodes_UnknownUser_profilePicture {
  isDefault: boolean | null;
  path: string;
}

export interface PresenceProviderUserQuery_confluenceUsers_nodes_UnknownUser {
  profilePicture: PresenceProviderUserQuery_confluenceUsers_nodes_UnknownUser_profilePicture | null;
  accountId: string | null;
  displayName: string | null;
  permissionType: SitePermissionType | null;
}

export type PresenceProviderUserQuery_confluenceUsers_nodes = PresenceProviderUserQuery_confluenceUsers_nodes_Anonymous | PresenceProviderUserQuery_confluenceUsers_nodes_KnownUser | PresenceProviderUserQuery_confluenceUsers_nodes_UnknownUser;

export interface PresenceProviderUserQuery_confluenceUsers {
  nodes: (PresenceProviderUserQuery_confluenceUsers_nodes | null)[] | null;
}

export interface PresenceProviderUserQuery {
  confluenceUsers: PresenceProviderUserQuery_confluenceUsers | null;
}

export interface PresenceProviderUserQueryVariables {
  accountIds?: (string | null)[] | null;
}
