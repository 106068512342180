import {
	participantColors,
	getParticipantColor,
	type ParticipantColor,
} from '@atlaskit/editor-shared-styles';
import { token } from '@atlaskit/tokens';

import type { Participant } from './presence-store';

/**
 * Retrieves the border color associated with a given string ID.
 *
 * @param id - The ID for which to get the border color. If undefined, a default color is returned.
 * @returns ParticipantColor object with text color and background color
 */
export const getParticipantColors = (id: string | undefined): ParticipantColor => {
	if (!id) {
		return participantColors[0];
	}

	return getParticipantColor(id).color;
};

/**
 * Retrieves the border color of the current user, which should always be blue.
 *
 * @returns ParticipantColor object with text color and background color
 */
export const getCurrentUserColors = (): ParticipantColor => {
	return {
		textColor: token('color.text.inverse'),
		backgroundColor: token('color.background.accent.blue.bolder'),
	};
};

export const getCurrentUserFromMap = (
	participants: Map<string, Participant>,
	sessionId: string | undefined,
) => {
	if (sessionId) {
		return [...participants.values()].find((p) => p.sessions[sessionId]);
	}
};

// the max number for the initial group of avatars before displaying a popover menu
export const MAX_GROUP_SIZE = 4;
export const CURRENT_USER_DUMMY_SESSION_ID = 'dummy-session-id';
