import React, { useCallback, useRef } from 'react';
import { useIntl, defineMessages } from 'react-intl-next';

import Avatar from '@atlaskit/avatar';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import type { AvatarProps } from '@atlaskit/avatar-group';
import type { BackgroundColor, TextColor } from '@atlaskit/primitives';
import { Flex, Box, Pressable, xcss } from '@atlaskit/primitives';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import type { PresenceActivity } from '@atlaskit/editor-common/collab';
import type { TriggerProps } from '@atlaskit/popup';

import { EditIcon } from './EditIcon';

const i18n = defineMessages({
	editing: {
		id: 'team-presence.menu.editing',
		defaultMessage: 'Editing',
		description:
			'The text is shown as a title of a section inside of a menu. The people in the section are editing a page',
	},
	viewing: {
		id: 'team-presence.menu.viewing',
		defaultMessage: 'Viewing',
		description: 'The text is used to indicate that the current user is `Viewing the page`',
	},
});

const containerStyles = xcss({
	position: 'relative',
});

const avatarBadgeStyles = xcss({
	position: 'absolute',
	bottom: '-2px',
	right: '-2px',
	height: '12px',
	width: '12px',
	borderRadius: '3px',
	color: 'color.text.inverse', // TODO: This will need to be swapped for the foregroundColor, based on sessionId
	boxShadow: 'elevation.shadow.overflow',
	fontWeight: token('font.weight.semibold'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '10px',
	zIndex: 'card',
});

const avatarPressableStyles = xcss({
	padding: 'space.0',
	margin: 'space.0',
	cursor: 'default',
	display: 'flex',
	alignItems: 'center',
	backgroundColor: 'color.background.neutral.subtle',
	borderRadius: 'border.radius.100',
	':focus-visible': {
		outlineColor: 'color.border.focused',
		outlineStyle: 'solid',
		outlineWidth: 'border.width.outline',
		outlineOffset: 'space.050',
	},
});

export type PresenceAvatarProps = AvatarProps & {
	activity: PresenceActivity;
	borderColor: string;
	textColor: string;
	contentMode: 'live' | 'edit' | 'view';
	contentId?: string;
	contentType?: string;
	analyticSource?: string;
	avatarSource?: string;
	isPresenceMenu?: boolean;
	buttonProps?: TriggerProps;
	onAvatarClick?: () => void;
};

enum EditorBadgeType {
	FIRST_INITIAL = 'FIRST_INITIAL',
	EDITOR_ICON = 'EDITOR_ICON',
}

type AvatarBadgeProps = {
	backgroundColor: string;
	textColor: string;
	letter: string;
	badgeType: EditorBadgeType;
};

const getBadgeType = (isInEditContentMode: boolean) => {
	if (isInEditContentMode) {
		return EditorBadgeType.FIRST_INITIAL;
	} else {
		return EditorBadgeType.EDITOR_ICON;
	}
};

const EditorAvatarBadge = ({ backgroundColor, textColor, letter, badgeType }: AvatarBadgeProps) => {
	/* eslint-disable @atlaskit/ui-styling-standard/no-imported-style-values,@atlaskit/design-system/consistent-css-prop-usage,@atlaskit/ui-styling-standard/no-unsafe-values */
	return (
		<Flex
			testId="editor-avatar-badge"
			alignItems="center"
			justifyContent="center"
			xcss={[
				avatarBadgeStyles,
				xcss({
					backgroundColor: backgroundColor as BackgroundColor,
					color: textColor as TextColor,
				}),
			]}
		>
			{badgeType === EditorBadgeType.EDITOR_ICON ? (
				<EditIcon testId="editor-avatar-badge-icon" aria-label="editor" />
			) : (
				<Box testId="editor-avatar-badge-initial" as="span">
					{letter}
				</Box>
			)}
		</Flex>
	);
	/* eslint-enable @atlaskit/ui-styling-standard/no-imported-style-values,@atlaskit/design-system/consistent-css-prop-usage,@atlaskit/ui-styling-standard/no-unsafe-values */
};

export const PresenceAvatar = ({
	src,
	name,
	activity,
	contentMode,
	contentId,
	contentType,
	analyticSource,
	avatarSource,
	size = 'small',
	borderColor,
	textColor,
	stackIndex,
	isPresenceMenu,
	onAvatarClick,
	buttonProps,
	...props
}: PresenceAvatarProps) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const avatarRef = useRef<HTMLElement>(null);

	const firstInitial = name?.substring(0, 1);
	const activityText =
		activity === 'editor' ? formatMessage(i18n.editing) : formatMessage(i18n.viewing);
	const label = `${name} (${activityText})`;
	const isInEditContentMode = contentMode === 'live' || contentMode === 'edit';

	const handleAvatarClick = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: analyticSource,
				action: 'clicked',
				actionSubject: 'presenceAvatar',
				product: 'confluence',
				attributes: {
					contentMode,
					contentId,
					contentType,
					avatarSource,
				},
			},
		}).fire();

		onAvatarClick?.();
	}, [
		avatarSource,
		analyticSource,
		contentId,
		contentMode,
		contentType,
		createAnalyticsEvent,
		onAvatarClick,
	]);

	return (
		<Tooltip content={label}>
			<Flex direction="column" alignItems="center" gap="space.0" xcss={containerStyles}>
				<Pressable
					xcss={avatarPressableStyles}
					testId="presence-avatar-btn"
					onClick={handleAvatarClick}
					tabIndex={avatarSource === 'presenceMenu' ? -1 : 0}
					{...buttonProps}
				>
					<Avatar
						{...props}
						borderColor={borderColor}
						appearance="circle"
						src={src}
						size={size}
						name={name}
						label={label}
						stackIndex={stackIndex}
						analyticsContext={{
							attributes: {
								contentMode,
								contentId,
								contentType,
								avatarSource,
							},
							source: analyticSource,
							actionSubjectId: 'presenceAvatar',
						}}
						ref={avatarRef}
					/>
					{activity === 'editor' && (
						<EditorAvatarBadge
							backgroundColor={borderColor}
							textColor={textColor}
							badgeType={getBadgeType(isInEditContentMode)}
							letter={firstInitial}
						/>
					)}
				</Pressable>
			</Flex>
		</Tooltip>
	);
};
