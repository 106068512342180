import gql from 'graphql-tag';

export const PresenceProviderAGGUserQuery = gql`
	query PresenceUsersQuery($accountIds: [ID!]!) {
		users(accountIds: $accountIds) {
			accountId
			name
			picture
		}
	}
`;
