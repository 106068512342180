import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

import { Box, Stack } from '@atlaskit/primitives';

import type { PageMode } from '@confluence/copy-content-link';
import { ErrorDisplay } from '@confluence/error-boundary';
import { fg } from '@confluence/feature-gating';
import { getAGGClient } from '@confluence/graphql';
import { useLivePageMode } from '@confluence/live-pages-utils/entry-points/useLivePagesStore';
import { useSessionData } from '@confluence/session-data';
import { useUnifiedShareDialogEligible } from '@confluence/experiment-unified-share-dialog';

import { NoCollaboratorsDialogShareOptions } from './NoCollaboratorsDialogShareOptions';
import { NoCollaboratorsDialogShareSuggestionMenu } from './NoCollaboratorsDialogShareSuggestionMenu';

const i18n = defineMessages({
	blogpostViewing: {
		id: 'team-presence.menu.empty-state.blogpost.viewing',
		defaultMessage: 'You’re the only one viewing this blog post.',
		description:
			'Text shown when the user is the only one viewing the blog post and has no other collaborators.',
	},
	blogpostEditing: {
		id: 'team-presence.menu.empty-state.blogpost.editing',
		defaultMessage: 'You’re the only one editing this blog post.',
		description:
			'Text shown when the user is the only one editing the blog post and has no other collaborators.',
	},
	pageViewing: {
		id: 'team-presence.menu.empty-state.page.viewing',
		defaultMessage: 'You’re the only one viewing this page.',
		description:
			'Text shown when the user is the only one viewing the page and has no other collaborators.',
	},
	pageEditing: {
		id: 'team-presence.menu.empty-state.page.editing',
		defaultMessage: 'You’re the only one editing this page.',
		description:
			'Text shown when the user is the only one editing the page and has no other collaborators.',
	},
	shareItWithOthersText: {
		id: 'team-presence.menu.empty-state.share-it-with-others',
		defaultMessage: 'Share it with others who might be interested.',
		description: 'Text encouraging the user to share the page with others.',
	},
	publishToShareItWithOthersText: {
		id: 'team-presence.menu.empty-state.publish-to-share-it-with-others',
		defaultMessage: 'Publish it to share with others who might be interested.',
		description: 'Text encouraging the user to share the page with others by publishing.',
	},
	copyLinkButtonText: {
		id: 'team-presence.menu.empty-state.copy-button',
		defaultMessage: 'Copy link',
		description: 'Call to action button to copy the link to the content.',
	},
	sharePageButtonText: {
		id: 'team-presence.menu.empty-state.page.share-button',
		defaultMessage: 'Share page',
		description: 'Call to action button to share the page with others.',
	},
	shareBlogPostButtonText: {
		id: 'team-presence.menu.empty-state.blogpost.share-button',
		defaultMessage: 'Share blog post',
		description: 'Call to action button to share the blog post with others.',
	},
	sharePageButtonClickedText: {
		id: 'team-presence.menu.empty-state.page.share-button.clicked',
		defaultMessage: 'Page shared',
		description: 'Button text to indicate that the page has been shared.',
	},
	shareBlogPostButtonClickedText: {
		id: 'team-presence.menu.empty-state.blogpost.share-button.clicked',
		defaultMessage: 'Blogpost shared',
		description: 'Button text to indicate that the blog post has been shared.',
	},
});

export type SuggestedUser = {
	id: string;
	name: string;
	picture: string;
	status: string;
};

export type NoCollaboratorsDialogProps = {
	onShareClick: (analyticsSource: string) => void;
	contentMode: 'live' | 'edit' | 'view';
	contentId: string;
	contentType: string;
	spaceKey: string;
	sessionId: string;
	testId?: string;
};

export const NoCollaboratorsDialog = ({
	contentMode,
	onShareClick,
	contentId,
	contentType,
	spaceKey,
	sessionId,
	testId,
}: NoCollaboratorsDialogProps) => {
	const { formatMessage } = useIntl();
	const { isUSDExperimentEnabled } = useUnifiedShareDialogEligible();

	const { cloudId, userId } = useSessionData();

	const { data, error, loading } = useQuery(
		gql`
			query NoCollaboratorsDialogRecommendationQuery(
				$cloudId: String!
				$userId: String!
				$sessionId: String!
			) {
				smarts {
					recommendedUser(
						recommendationsQuery: {
							context: { userId: $userId, tenantId: $cloudId }
							modelRequestParams: {
								caller: "confluence-page-presence"
								experience: "CgUserNearbyUser"
							}
							maxNumberOfResults: 10
							requestingUserId: $userId
							sessionId: $sessionId
						}
					) {
						id
						user {
							accountId
							accountStatus
							name
							picture
						}
					}
				}
			}
		`,
		{
			client: getAGGClient(),
			variables: {
				cloudId,
				userId,
				sessionId,
			},
			skip: !userId || !cloudId || !fg('confluence_team_presence_user_suggestions'),
		},
	);

	const suggestions = (data?.smarts?.recommendedUser || []).map((suggestion: any) => ({
		id: suggestion.user.accountId,
		name: suggestion.user.name,
		status: suggestion.user.accountStatus,
		picture: suggestion.user.picture,
	}));

	const [{ isEditMode: isLiveEditMode }] = useLivePageMode();

	// Determine if the user is in edit mode. Live pages supports both edit and view modes, so
	// we need to do an additional check to see if we're in edit mode on a live pages.
	const isInEditMode = contentMode === 'edit' || (contentMode === 'live' && isLiveEditMode);

	const pageMode: PageMode = isInEditMode ? 'edit' : 'view';

	/*
	On edit classic page, there is no Share button (only "Update" button).
	Since there is no Share button on edit classic page with a share dialog, clicking the Share button on this modal doesn't do anything,
	so we are disabling the Share button on edit mode.
	*/
	const isShareButtonDisabled =
		!(fg('confluence_frontend_unified_restrict_and_share') || isUSDExperimentEnabled) &&
		contentMode === 'edit';

	const contentTypeToTranslationMap:
		| Record<
				string,
				{
					modalDescriptionLineOne: string;
					modalDescriptionLineTwo: string;
					shareButtonText: string;
					shareButtonClickedText: string;
				}
		  >
		| undefined = {
		blogpost: {
			modalDescriptionLineOne: formatMessage(
				pageMode === 'edit' ? i18n.blogpostEditing : i18n.blogpostViewing,
			),
			modalDescriptionLineTwo: formatMessage(
				isShareButtonDisabled ? i18n.publishToShareItWithOthersText : i18n.shareItWithOthersText,
			),
			shareButtonText: formatMessage(i18n.shareBlogPostButtonText),
			shareButtonClickedText: formatMessage(i18n.shareBlogPostButtonClickedText),
		},
		page: {
			modalDescriptionLineOne: formatMessage(
				pageMode === 'edit' ? i18n.pageEditing : i18n.pageViewing,
			),
			modalDescriptionLineTwo: formatMessage(
				isShareButtonDisabled ? i18n.publishToShareItWithOthersText : i18n.shareItWithOthersText,
			),
			shareButtonText: formatMessage(i18n.sharePageButtonText),
			shareButtonClickedText: formatMessage(i18n.sharePageButtonClickedText),
		},
	};

	const translations = (contentTypeToTranslationMap ?? {})[contentType];

	if (loading) {
		return null;
	}

	return (
		<Stack testId={testId}>
			<Box paddingInline="space.200" paddingBlockStart="space.200">
				{translations.modalDescriptionLineOne}
				&nbsp;
				{translations.modalDescriptionLineTwo}
			</Box>
			{error && <ErrorDisplay error={error} />}
			{!isShareButtonDisabled && suggestions.length ? (
				<NoCollaboratorsDialogShareSuggestionMenu
					contentId={contentId}
					contentMode={contentMode}
					contentType={contentType}
					pageMode={pageMode}
					suggestions={suggestions}
					shareButtonLabelBeforeClick={translations.shareButtonText}
					shareButtonLabelAfterClick={translations.shareButtonClickedText}
				/>
			) : (
				<NoCollaboratorsDialogShareOptions
					contentId={contentId}
					contentMode={contentMode}
					contentType={contentType}
					pageMode={pageMode}
					spaceKey={spaceKey}
					copyLinkButtonLabel={formatMessage(i18n.copyLinkButtonText)}
					shareButtonLabel={translations.shareButtonText}
					isShareButtonDisabled={isShareButtonDisabled}
					onShareClick={onShareClick}
				/>
			)}
		</Stack>
	);
};
